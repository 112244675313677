import { IRetailer } from '~/types/retailer'
import { NewVehicle, UsedVehicle } from '~/types/vehicle'

export const useRetailer = (
  retailer?: Ref<IRetailer | null>,
  vehicle?: Ref<NewVehicle | UsedVehicle | null>,
  vehicleRetailer?: Ref<IRetailer | null>,
  smart?: Ref<boolean>
) => {
  const isSmart = computed<boolean>(() => {
    return smart?.value ? true : vehicle?.value?.Brand?.Description === 'SMART'
  })

  const retailerAddress = computed<string>(
    () =>
      `${retailer?.value?.Description}, ${retailer?.value?.Street}, ${retailer?.value?.City}, ${retailer?.value?.Postcode}`
  )

  const hasOpeningTimes = computed<boolean | undefined>(() => {
    return (
      retailer &&
      retailer?.value?.OpeningTimes &&
      (retailer?.value?.OpeningTimes?.New.length > 0 ||
        retailer?.value?.OpeningTimes?.Used.length > 0)
    )
  })

  const smartRetailerName = computed<string | undefined>(
    () =>
      retailer?.value?.smartDescription ||
      vehicleRetailer?.value?.smartDescription ||
      retailer?.value?.Description
  )

  const retailerName = computed<string | undefined>(() =>
    isSmart.value ? smartRetailerName.value : retailer?.value?.Description
  )

  const retailerPhone = computed(() => {
    const regularPhone =
      retailer?.value?.DisplayPhoneNumberNew || retailer?.value?.Phone || null
    return isSmart.value ? retailer?.value?.smartPhone : regularPhone
  })

  const owningRetailer = computed<IRetailer | undefined>(
    () => vehicle?.value?.OwningRetailer || vehicle?.value?.Retailer
  )

  const owningRetailerDescription = computed<string | undefined>(() => {
    if (isSmart.value) {
      return (
        owningRetailer?.value?.smartDescription ||
        owningRetailer?.value?.Description
      )
    }
    return owningRetailer.value?.Description
  })

  const isNotOwningRetailer = computed<boolean>(
    () => retailer?.value?.Id !== owningRetailer?.value?.Id
  )

  return {
    retailerAddress,
    retailerName,
    smartRetailerName,
    retailerPhone,
    owningRetailer,
    owningRetailerDescription,
    isNotOwningRetailer,
    isSmart,
    hasOpeningTimes
  }
}
